import { AdministradorDeSucursales } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface AdmSucProps {
	admSuc: number;
	handleAdmSuc: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AdmSucT = (props: AdmSucProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleAdmSuc}>
					<div className="module-box">Adm. Sucursales</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={AdministradorDeSucursales.noAgregar}
								type="radio"
								name="admSuc"
								id="admSuc-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span style={{ display: 'none' }}>Básico 1</span>
							<input
								className="admSuc-radio"
								value={AdministradorDeSucursales.basico}
								type="radio"
								name="admSuc"
								id="discount"
								disabled={true}
								style={{ display: 'none' }}
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
              <span>Ligero 2</span>
							<input
								className="admSuc-radio"
								value={AdministradorDeSucursales.ligero}
								type="radio"
								name="admSuc"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
              <span>Profesional 3</span>
							<input
								className="admSuc-radio"
								value={AdministradorDeSucursales.profesional}
								type="radio"
								name="admSuc"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
              <span>Premium 5</span>
							<input
								className="admSuc-radio"
								value={AdministradorDeSucursales.premium}
								type="radio"
								name="admSuc"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
              <span>Corporativo 10</span>
							<input
								className="admSuc-radio"
								value={AdministradorDeSucursales.corporativo}
								type="radio"
								name="admSuc"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputAdmSuc = document.querySelectorAll(
									'input[name="admSuc"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputAdmSuc.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="admSuc"
							id="admSucSelect"
						>
							<option value="">Licenciamientos (15+)</option>
							<option value={AdministradorDeSucursales.incremento1}>15</option>
							<option value={AdministradorDeSucursales.incremento2}>20</option>
							<option value={AdministradorDeSucursales.incremento3}>25</option>
							<option value={AdministradorDeSucursales.incremento4}>30</option>
							<option value={AdministradorDeSucursales.incremento5}>35</option>
							<option value={AdministradorDeSucursales.incremento6}>40</option>
							<option value={AdministradorDeSucursales.incremento7}>45</option>
							<option value={AdministradorDeSucursales.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{/* {props.admSuc === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  }).format(0)
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.admSuc)} */}
						{/* remove decimals */}
						{props.admSuc === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.admSuc)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
