export const Page404 = () => {
	return (
		<>
			<h1
				style={{
					color: '#202427',
					textAlign: 'center',
					fontSize: '2rem',
					padding: '1rem',
					// reveal text on first load
				}}
			>
				Parece que esta página no existe.
			</h1>
			<p
				style={{
					color: '#202427',
					textAlign: 'center',
					fontSize: '1.5rem',
					padding: '0.5rem',
				}}
			>
				Por favor selecciona una modalidad de pago, en Tradicional o en Suscripción
			</p>
		</>
	);
};
