import { PuntoDeVenta } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface PuntoDeVentaProps {
	puntoDeVenta: number;
	handlePuntoDeVenta: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PuntoDeVentaT = (props: PuntoDeVentaProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handlePuntoDeVenta}>
					<div className="module-box">Punto De Venta</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={PuntoDeVenta.noAgregar}
								type="radio"
								name="puntoDeVenta"
								id="puntoDeVenta-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								className="puntoDeVenta-radio"
								value={PuntoDeVenta.basico}
								type="radio"
								name="puntoDeVenta"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								className="puntoDeVenta-radio"
								value={PuntoDeVenta.ligero}
								type="radio"
								name="puntoDeVenta"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								className="puntoDeVenta-radio"
								value={PuntoDeVenta.profesional}
								type="radio"
								name="puntoDeVenta"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								className="puntoDeVenta-radio"
								value={PuntoDeVenta.premium}
								type="radio"
								name="puntoDeVenta"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								className="puntoDeVenta-radio"
								value={PuntoDeVenta.corporativo}
								type="radio"
								name="puntoDeVenta"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputPuntoDeVenta = document.querySelectorAll(
									'input[name="puntoDeVenta"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputPuntoDeVenta.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="puntoDeVenta"
							id="puntoDeVentaSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={PuntoDeVenta.incremento1}>15</option>
							<option value={PuntoDeVenta.incremento2}>20</option>
							<option value={PuntoDeVenta.incremento3}>25</option>
							<option value={PuntoDeVenta.incremento4}>30</option>
							<option value={PuntoDeVenta.incremento5}>35</option>
							<option value={PuntoDeVenta.incremento6}>40</option>
							<option value={PuntoDeVenta.incremento7}>45</option>
							<option value={PuntoDeVenta.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.puntoDeVenta === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.puntoDeVenta)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
