import React from 'react';

import '../styles/moduleT.css';

interface SicsProps {
	sicsSus: number;
	handleSicsSus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SicsS = (props: SicsProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group">
					<div className="module-box">SICS</div>

					<div className="radio-box"></div>
					<div className="radio-box"></div>
					<div className="radio-box"></div>
					<div className="radio-box"></div>
					<div className="radio-box"></div>
					<div className="radio-box"></div>

					<div className="select-box">
						{/*  input text number*/}

						<input
							className="sics-input"
							name="sics"
							placeholder="Por sucursal"
							style={{
								width: '100%',
								height: '100%',
								border: 'none',
								backgroundColor: '#c8c9ca',
								fontSize: '0.7rem',
								padding: '0.5rem',
							}}
							onChange={props.handleSicsSus}
							type="number"
							max="50"
							min="0"
						/>
					</div>

					<div className="total-box">
						{props.sicsSus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.sicsSus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
