import { Outlet, NavLink } from 'react-router-dom';
import styles from './Navbar.module.css';

export const Navbar = () => {
	let activeStyle: {
		color: string;
		borderBottom: string;
	};

	activeStyle = {
		color: '#ff8623',
		borderBottom: '2px solid #ff8623',
	};

	return (
		<>
			<div className={styles.navbarGrid}>
				<div className={styles.navbarGridItem}>
					<NavLink
						className={styles.navbarLink}
						style={({ isActive }) => (isActive ? activeStyle : undefined)}
						to="tradicional"
					>
						Tradicional
					</NavLink>
				</div>
				<div className={styles.navbarGridItem}>
					<NavLink
						className={styles.navbarLink}
						style={({ isActive }) => (isActive ? activeStyle : undefined)}
						to="suscripcion"
					>
						Suscripción
					</NavLink>
				</div>
			</div>

			<Outlet />
		</>
	);
};
