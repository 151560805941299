
interface ModuleTypesSus {
	id: number;
	noAgregar: number;
	basico: number;
	ligero: number;
	profesional: number;
	premium: number;
	corporativo: number;
	incremento: number;
	incremento1: number;
	incremento2: number;
	incremento3: number;
	incremento4: number;
	incremento5: number;
	incremento6: number;
	incremento7: number;
	incremento8: number;
}

export const Contabilidad: ModuleTypesSus = {
  id: 1,
  noAgregar: 0,
  basico: 415,
  ligero: 465,
  profesional: 585,
  premium: 840,
  corporativo: 1525,
  incremento: 865,
  incremento1: 1525 + 865,
  incremento2: 1525 + 865 * 2,
  incremento3: 1525 + 865 * 3,
  incremento4: 1525 + 865 * 4,
  incremento5: 1525 + 865 * 5,
  incremento6: 1525 + 865 * 6,
  incremento7: 1525 + 865 * 7,
  incremento8: 1525 + 865 * 8,
};

export const Bancos: ModuleTypesSus = {
  id: 2,
  noAgregar: 0,
  basico: 415,
  ligero: 465,
  profesional: 585,
  premium: 840,
  corporativo: 1525,
  incremento: 865,
  incremento1: 1525 + 865,
  incremento2: 1525 + 865 * 2,
  incremento3: 1525 + 865 * 3,
  incremento4: 1525 + 865 * 4,
  incremento5: 1525 + 865 * 5,
  incremento6: 1525 + 865 * 6,
  incremento7: 1525 + 865 * 7,
  incremento8: 1525 + 865 * 8,
};

export const Nomina: ModuleTypesSus = {
  id: 3,
  noAgregar: 0,
  basico: 440,
  ligero: 500,
  profesional: 620,
  premium: 895,
  corporativo: 1570,
  incremento: 935,
  incremento1: 1570 + 935,
  incremento2: 1570 + 935 * 2,
  incremento3: 1570 + 935 * 3,
  incremento4: 1570 + 935 * 4,
  incremento5: 1570 + 935 * 5,
  incremento6: 1570 + 935 * 6,
  incremento7: 1570 + 935 * 7,
  incremento8: 1570 + 935 * 8,
};

export const Inventarios: ModuleTypesSus = {
  id: 4,
  noAgregar: 0,
  basico: 205,
  ligero: 315,
  profesional: 360,
  premium: 585,
  corporativo: 1420,
  incremento: 580,
  incremento1: 1420 + 580,
  incremento2: 1420 + 580 * 2,
  incremento3: 1420 + 580 * 3,
  incremento4: 1420 + 580 * 4,
  incremento5: 1420 + 580 * 5,
  incremento6: 1420 + 580 * 6,
  incremento7: 1420 + 580 * 7,
  incremento8: 1420 + 580 * 8,
};

export const CuentasPorCobrar: ModuleTypesSus = {
  id: 5,
  noAgregar: 0,
  basico: 205,
  ligero: 315,
  profesional: 360,
  premium: 585,
  corporativo: 1420,
  incremento: 580,
  incremento1: 1420 + 580,
  incremento2: 1420 + 580 * 2,
  incremento3: 1420 + 580 * 3,
  incremento4: 1420 + 580 * 4,
  incremento5: 1420 + 580 * 5,
  incremento6: 1420 + 580 * 6,
  incremento7: 1420 + 580 * 7,
  incremento8: 1420 + 580 * 8,
};

export const Compras: ModuleTypesSus = {
  id: 6,
  noAgregar: 0,
  basico: 245,
  ligero: 380,
  profesional: 475,
  premium: 685,
  corporativo: 1580,
  incremento: 695,
  incremento1: 1580 + 695,
  incremento2: 1580 + 695 * 2,
  incremento3: 1580 + 695 * 3,
  incremento4: 1580 + 695 * 4,
  incremento5: 1580 + 695 * 5,
  incremento6: 1580 + 695 * 6,
  incremento7: 1580 + 695 * 7,
  incremento8: 1580 + 695 * 8,
};

export const CuentasPorPagar: ModuleTypesSus = {
  id: 7,
  noAgregar: 0,
  basico: 205,
  ligero: 315,
  profesional: 360,
  premium: 585,
  corporativo: 1420,
  incremento: 580,
  incremento1: 1420 + 580,
  incremento2: 1420 + 580 * 2,
  incremento3: 1420 + 580 * 3,
  incremento4: 1420 + 580 * 4,
  incremento5: 1420 + 580 * 5,
  incremento6: 1420 + 580 * 6,
  incremento7: 1420 + 580 * 7,
  incremento8: 1420 + 580 * 8,
};

export const PuntoDeVenta: ModuleTypesSus = {
  id: 8,
  noAgregar: 0,
  basico: 345,
  ligero: 465,
  profesional: 660,
  premium: 1055,
  corporativo: 1860,
  incremento: 1050,
  incremento1: 1860 + 1050,
  incremento2: 1860 + 1050 * 2,
  incremento3: 1860 + 1050 * 3,
  incremento4: 1860 + 1050 * 4,
  incremento5: 1860 + 1050 * 5,
  incremento6: 1860 + 1050 * 6,
  incremento7: 1860 + 1050 * 7,
  incremento8: 1860 + 1050 * 8,
};

export const Ventas: ModuleTypesSus = {
  id: 9,
  noAgregar: 0,
  basico: 245,
  ligero: 380,
  profesional: 475,
  premium: 685,
  corporativo: 1580,
  incremento: 695,
  incremento1: 1580 + 695,
  incremento2: 1580 + 695 * 2,
  incremento3: 1580 + 695 * 3,
  incremento4: 1580 + 695 * 4,
  incremento5: 1580 + 695 * 5,
  incremento6: 1580 + 695 * 6,
  incremento7: 1580 + 695 * 7,
  incremento8: 1580 + 695 * 8,
};

export const EnRuta: ModuleTypesSus = {
  id: 10,
  noAgregar: 0,
  basico: 0,
  ligero: 370,
  profesional: 500,
  premium: 765,
  corporativo: 1340,
  incremento: 670,
  incremento1: 1340 + 670,
  incremento2: 1340 + 670 * 2,
  incremento3: 1340 + 670 * 3,
  incremento4: 1340 + 670 * 4,
  incremento5: 1340 + 670 * 5,
  incremento6: 1340 + 670 * 6,
  incremento7: 1340 + 670 * 7,
  incremento8: 1340 + 670 * 8,
};

export const SyncE: ModuleTypesSus = {
  id: 11,
  noAgregar: 0,
  basico: 295,
  ligero: 400,
  profesional: 470,
  premium: 600,
  corporativo: 880,
  incremento: 440,
  incremento1: 880 + 440,
  incremento2: 880 + 440 * 2,
  incremento3: 880 + 440 * 3,
  incremento4: 880 + 440 * 4,
  incremento5: 880 + 440 * 5,
  incremento6: 880 + 440 * 6,
  incremento7: 880 + 440 * 7,
  incremento8: 880 + 440 * 8,
};

export const CEOmovil: ModuleTypesSus = {
  id: 12,
  noAgregar: 0,
  basico: 0,
  ligero: 300,
  profesional: 405,
  premium: 615,
  corporativo: 820,
  incremento: 410,
  incremento1: 820 + 410,
  incremento2: 820 + 410 * 2,
  incremento3: 820 + 410 * 3,
  incremento4: 820 + 410 * 4,
  incremento5: 820 + 410 * 5,
  incremento6: 820 + 410 * 6,
  incremento7: 820 + 410 * 7,
  incremento8: 820 + 410 * 8,
};

export const AdministradorDeSucursales: ModuleTypesSus = {
  id: 13,
  noAgregar: 0,
  basico: 0,
  ligero: 250,
  profesional: 350,
  premium: 500,
  corporativo: 850,
  incremento: 500,
  incremento1: 850 + 500,
  incremento2: 850 + 500 * 2,
  incremento3: 850 + 500 * 3,
  incremento4: 850 + 500 * 4,
  incremento5: 850 + 500 * 5,
  incremento6: 850 + 500 * 6,
  incremento7: 850 + 500 * 7,
  incremento8: 850 + 500 * 8,
};

export const SICS = {
  id: 13,
  noAgregar: 0,
  individual: 260,
};

export const SIMB = {
  id: 14,
  noAgregar: 0,
  individual: 400,
};

export const FAEL = {
  id: 15,
  noAgregar: 0,
  individual: 1000,
};

export const AllModules: ModuleTypesSus[] = [
	Contabilidad,
	Bancos,
	Nomina,
	Inventarios,
	CuentasPorCobrar,
	Compras,
	CuentasPorPagar,
	PuntoDeVenta,
	Ventas,
	EnRuta,
	SyncE,
	CEOmovil,
	AdministradorDeSucursales,
];
