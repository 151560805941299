import styles from './TotalTrad.module.css';
import '../styles/susButtons.css';
import React from 'react';
import { useState, useEffect } from 'react';

type TotalSuscripcionProps = {
	getTotalSus: () => number;
	getDiscountSus: () => number | string;
};

export const TotalSuscripcion = (props: TotalSuscripcionProps) => {
	const [toggleMensual, setToggleMensual] = useState<boolean>(false);
	const [toggleSemestral, setToggleSemestral] = useState<boolean>(false);
	const [toggleAnual, setToggleAnual] = useState<boolean>(false);

	let discountMensual = 0;
	let discountSemestral5 = 0.05;
	let discountAnual10 = 0.1;

	const handleToggleMensual = () => {
		setToggleMensual(!toggleMensual);
		setToggleSemestral(false);
		setToggleAnual(false);
	};

	const handleToggleSemestral = () => {
		setToggleSemestral(!toggleSemestral);
		setToggleMensual(false);
		setToggleAnual(false);
	};

	const handleToggleAnual = () => {
		setToggleAnual(!toggleAnual);
		setToggleMensual(false);
		setToggleSemestral(false);
	};

	const setToggleMensualTrueOnLoad = () => {
		setToggleMensual(true);
	};

	useEffect(() => {
		setToggleMensualTrueOnLoad();
	}, []);

	return (
		<>
			<div className="btn-container">
				{/*	toggle button mensual*/}
				<button
					// default button active
					className={`btn ${toggleMensual ? 'btn-active' : 'btn-inactive'}`}
					onClick={handleToggleMensual}
				>
					Pago Mensual
				</button>
				{/*	toggle button semestral*/}
				<button
					className={`btn ${toggleSemestral ? 'btn-active' : 'btn-inactive'}`}
					onClick={handleToggleSemestral}
				>
					Pago Semestral
				</button>
				{/*	toggle button anual*/}
				<button className={`btn ${toggleAnual ? 'btn-active' : 'btn-inactive'}`} onClick={handleToggleAnual}>
					Pago Anual
				</button>
			</div>
			<div className={styles.box}>
				<div className={styles.totalContainer}>
					<div className={styles.price}>
						<div>Sub Total</div>
						{toggleMensual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotalSus()) + '/Mensual'
							: toggleSemestral
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotalSus() * 6) + '/Semestral'
							: toggleAnual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotalSus() * 12) + '/Anual'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotalSus())}
					</div>

					<div className={styles.price}>
						<div>Descuento Por Volumen</div>
						{/*{new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(Number(props.getDiscountSus()))}*/}
						{toggleMensual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscountSus())) + '/Mensual'
							: toggleSemestral
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscountSus()) * 6) + '/Semestral'
							: toggleAnual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscountSus()) * 12) + '/Anual'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscountSus()))}
					</div>

					<div className={styles.price}>
						<div>Descuento Por Frecuencia</div>
						{/*{toggleMensual ? new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(Number(props.getTotalSus() * discountMensual)) : toggleSemestral ? new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(Number(props.getTotalSus() * discountSemestral5)) : toggleAnual ? new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(Number(props.getTotalSus() * discountAnual10)) : new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(0)}*/}
						{toggleMensual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getTotalSus() * discountMensual)) + '/Mensual'
							: toggleSemestral
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getTotalSus() * discountSemestral5) * 6) + '/Semestral'
							: toggleAnual
							? new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getTotalSus() * discountAnual10) * 12) + '/Anual'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(0)}
					</div>

					<div className={styles.price}>
						<div>Total</div>
						{/*{new Intl.NumberFormat('mx-MX', {*/}
						{/*  style: 'currency',*/}
						{/*  currency: 'MXN',*/}
						{/*}).format(props.getTotalSus() - Number(props.getDiscountSus()))}*/}
						{
							/*
              if toggle semestral is true first apply discountSemestral5 to getTotalSus() and then substract getDiscountSus()
              and then multiply by 6, result must be positve number
               */
							//TODO FIX THIS
							// toggleMensual
							// 	? props.getTotalSus() - Number(props.getDiscountSus())
							// 	: toggleSemestral
							// 	? (props.getTotalSus() - Number(props.getDiscountSus())) * 6 -
							// 	  props.getTotalSus() * discountSemestral5 * 6
							// 	: toggleAnual
							// 	? (props.getTotalSus() - Number(props.getDiscountSus())) * 12 -
							// 	  props.getTotalSus() * discountAnual10 * 12
							// 	: new Intl.NumberFormat('mx-MX', {
							// 			style: 'currency',
							// 			currency: 'MXN',
							// 	  }).format(0)
							toggleMensual
								? new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'MXN',
								  }).format(props.getTotalSus() - Number(props.getDiscountSus())) + '/Mensual'
								: toggleSemestral
								? new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'MXN',
								  }).format(
										(props.getTotalSus() - Number(props.getDiscountSus())) * 6 -
											props.getTotalSus() * discountSemestral5 * 6
								  ) + '/Semestral'
								: toggleAnual
								? new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'MXN',
								  }).format(
										(props.getTotalSus() - Number(props.getDiscountSus())) * 12 -
											props.getTotalSus() * discountAnual10 * 12
								  ) + '/Anual'
								: new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'MXN',
								  }).format(props.getTotalSus() - Number(props.getDiscountSus())) + '/Mensual'
						}
					</div>
					<div className={styles.advice}>
						Nota: Esta es sólo una estimación. No incluye IVA, instalación, ni PDA.
					</div>
				</div>
			</div>
		</>
	);
};
