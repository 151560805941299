import './App.css';
import { Routes, Route } from 'react-router-dom';
import Tradicional from './pages/Tradicional';
import { Suscripcion } from './pages/Suscripcion';
import { Home } from './pages/Home';
import { Navbar } from './components/Navbar';
import { Banner } from './components/Banner';
import { Page404 } from './pages/Page404';

function App() {
	return (
		<>
			<Banner />
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/tradicional" element={<Tradicional />} />
				<Route path="/suscripcion" element={<Suscripcion />} />
				<Route path="*" element={<Page404 />} />
			</Routes>
			{/* 404 page */}
		</>
	);
}

export default App;
