import React, { useState } from 'react';
import styles from '../styles/tradicional.module.css';
import { HeaderModulesOne } from '../components/HeaderModulesOne';
import { ContabilidadT } from '../modulos-tradicional/ContabilidadT';
import { BancosT } from '../modulos-tradicional/BancosT';
import { NominaT } from '../modulos-tradicional/NominaT';
import { InventariosT } from '../modulos-tradicional/InventariosT';
import { CuentasPorCobrarT } from '../modulos-tradicional/CuentasPorCobrarT';
import { ComprasT } from '../modulos-tradicional/ComprasT';
import { CuentasPorPagarT } from '../modulos-tradicional/CuentasPorPagarT';
import { PuntoDeVentaT } from '../modulos-tradicional/PuntoDeVentaT';
import { VentasT } from '../modulos-tradicional/VentasT';
import { EnRutaT } from '../modulos-tradicional/EnRutaT';
import { SyncET } from '../modulos-tradicional/SyncET';
import { CEOMovilT } from '../modulos-tradicional/CEOMovilT';
import { AdmSucT } from '../modulos-tradicional/AdmSucT';
import { SimbT } from '../modulos-tradicional/SimbT';
import { TotalTradicional } from '../components/TotalTradicional';
import { TopHeader } from '../components/TopHeader';
import { SIMB } from '../precios-tradicional/precios';
import { FAEL } from '../precios-tradicional/precios';
import { FaelT } from '../modulos-tradicional/FaelT';
export default function Tradicional() {
	const [contabilidad, setContabilidad] = useState<number>(0);
	const [bancos, setBancos] = useState<number>(0);
	const [nomina, setNomina] = useState<number>(0);
	const [inventarios, setInventarios] = useState<number>(0);
	const [cuentasPorCobrar, setCuentasPorCobrar] = useState<number>(0);
	const [compras, setCompras] = useState<number>(0);
	const [cuentasPorPagar, setCuentasPorPagar] = useState<number>(0);
	const [puntoDeVenta, setPuntoDeVenta] = useState<number>(0);
	const [ventas, setVentas] = useState<number>(0);
	const [enRuta, setEnRuta] = useState<number>(0);
	const [syncE, setSyncE] = useState<number>(0);
	const [ceoMovil, setCeoMovil] = useState<number>(0);
	const [admSuc, setAdmSuc] = useState<number>(0);
	const [simb, setSimb] = useState<number>(0);
	const [fael, setFael] = useState<number>(0);

	const handleContabilidad = (e: React.ChangeEvent<HTMLInputElement>) => {
		setContabilidad(Number(e.target.value));
		let contabilidadMenu = document.getElementById('contabilidadSelect') as HTMLSelectElement;
		let inputContabilidad = document.querySelectorAll(
			'input[name="contabilidad"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputContabilidad.length >= 1) {
			contabilidadMenu.selectedIndex = 0;
		}
	};

	const handleBancos = (e: React.ChangeEvent<HTMLInputElement>) => {
		setBancos(Number(e.target.value));
		let bancosMenu = document.getElementById('bancosSelect') as HTMLSelectElement;
		let inputBancos = document.querySelectorAll(
			'input[name="bancos"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputBancos.length >= 1) {
			bancosMenu.selectedIndex = 0;
		}
	};

	const handleNomina = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNomina(Number(e.target.value));
		let nominaMenu = document.getElementById('nominaSelect') as HTMLSelectElement;
		let inputNomina = document.querySelectorAll(
			'input[name="nomina"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputNomina.length >= 1) {
			nominaMenu.selectedIndex = 0;
		}
	};

	const handleInventarios = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInventarios(Number(e.target.value));
		let inventariosMenu = document.getElementById('inventariosSelect') as HTMLSelectElement;
		let inputInventarios = document.querySelectorAll(
			'input[name="inventarios"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputInventarios.length >= 1) {
			inventariosMenu.selectedIndex = 0;
		}
	};

	const handleCuentasPorCobrar = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCuentasPorCobrar(Number(e.target.value));
		let cuentasPorCobrarMenu = document.getElementById('cuentasPorCobrarSelect') as HTMLSelectElement;
		let inputCuentasPorCobrar = document.querySelectorAll(
			'input[name="cuentasPorCobrar"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCuentasPorCobrar.length >= 1) {
			cuentasPorCobrarMenu.selectedIndex = 0;
		}
	};

	const handleCompras = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCompras(Number(e.target.value));
		let comprasMenu = document.getElementById('comprasSelect') as HTMLSelectElement;
		let inputCompras = document.querySelectorAll(
			'input[name="compras"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCompras.length >= 1) {
			comprasMenu.selectedIndex = 0;
		}
	};

	const handleCuentasPorPagar = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCuentasPorPagar(Number(e.target.value));
		let cuentasPorPagarMenu = document.getElementById('cuentasPorPagarSelect') as HTMLSelectElement;
		let inputCuentasPorPagar = document.querySelectorAll(
			'input[name="cuentasPorPagar"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCuentasPorPagar.length >= 1) {
			cuentasPorPagarMenu.selectedIndex = 0;
		}
	};

	const handlePuntoDeVenta = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPuntoDeVenta(Number(e.target.value));
		let puntoDeVentaMenu = document.getElementById('puntoDeVentaSelect') as HTMLSelectElement;
		let inputPuntoDeVenta = document.querySelectorAll(
			'input[name="puntoDeVenta"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputPuntoDeVenta.length >= 1) {
			puntoDeVentaMenu.selectedIndex = 0;
		}
	};

	const handleVentas = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVentas(Number(e.target.value));
		let ventasMenu = document.getElementById('ventasSelect') as HTMLSelectElement;
		let inputVentas = document.querySelectorAll(
			'input[name="ventas"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputVentas.length >= 1) {
			ventasMenu.selectedIndex = 0;
		}
	};

	const handleEnRuta = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEnRuta(Number(e.target.value));
		let enRutaMenu = document.getElementById('enRutaSelect') as HTMLSelectElement;
		let inputEnRuta = document.querySelectorAll(
			'input[name="enRuta"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputEnRuta.length >= 1) {
			enRutaMenu.selectedIndex = 0;
		}
	};

	const handleSyncE = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSyncE(Number(e.target.value));
		let syncEMenu = document.getElementById('syncESelect') as HTMLSelectElement;
		let inputSyncE = document.querySelectorAll('input[name="syncE"]:checked') as NodeListOf<HTMLInputElement>;
		if (inputSyncE.length >= 1) {
			syncEMenu.selectedIndex = 0;
		}
	};

	const handleCeoMovil = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCeoMovil(Number(e.target.value));
		let ceoMovilMenu = document.getElementById('ceoMovilSelect') as HTMLSelectElement;
		let inputCeoMovil = document.querySelectorAll(
			'input[name="ceoMovil"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCeoMovil.length >= 1) {
			ceoMovilMenu.selectedIndex = 0;
		}
	};

	const handleAdmSuc = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdmSuc(Number(e.target.value));
		let admSucMenu = document.getElementById('admSucSelect') as HTMLSelectElement;
		let inputAdmSuc = document.querySelectorAll(
			'input[name="admSuc"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputAdmSuc.length >= 1) {
			admSucMenu.selectedIndex = 0;
		}
	};

  const handleSimb = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only positive numbers
    if (Number(e.target.value) >= 0) {
      setSimb(Number(e.target.value) * SIMB.individual);
    } else {
      setSimb(0);
    }
    // setSicsSus(Number(e.target.value) * SICS.individual);
  };

  const handleFael = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (value === 1) {
      setFael(FAEL.individual);
    } else {
      setFael(0);
      e.target.value = '0';
    }
  };

	// console.log buttons active

	// const [contabilidad, setContabilidad] = useState(
	// 	precios.contabilidad.noAgregar
	// );

	// const handleContabilidad = (event) => {
	// 	setContabilidad(event.target.value);
	// };

	// const radioContabilidadBasico = useRef({
	// 	checked: contabilidad === precios.contabilidad.basico ? true : false,
	// });

	// const radioContabilidadLigero = useRef({
	// 	checked: contabilidad === precios.contabilidad.ligero ? true : false,
	// });

	// const radioContabilidadProfesional = useRef({
	// 	checked: contabilidad === precios.contabilidad.profesional ? true : false,
	// });

	// const radioContabilidadPremium = useRef({
	// 	checked: contabilidad === precios.contabilidad.premium ? true : false,
	// });

	// const radioContabilidadCorporativo = useRef({
	// 	checked: contabilidad === precios.contabilidad.corporativo ? true : false,
	// });

	// console.log(
	// 	`%c RADIO BASICO IS ${radioContabilidadBasico.current.checked}`,
	// 	'color: green'
	// );
	// console.log(
	// 	`%c RADIO LIGERO IS ${radioContabilidadLigero.current.checked}`,
	// 	'color: yellow'
	// );
	// console.log(
	// 	`%c RADIO PROFESIONAL IS ${radioContabilidadProfesional.current.checked}`,
	// 	'color: red'
	// );
	// console.log(
	// 	`%c RADIO PREMIUM IS ${radioContabilidadPremium.current.checked}`,
	// 	'color: pink'
	// );
	// console.log(
	// 	`%c RADIO CORPORATIVO IS ${radioContabilidadCorporativo.current.checked}`,
	// 	'color: orange'
	// );

	// console.log number of radios checked true

	// const radiosInDOMcheckedTrue = () => {
	// 	const radios = document.querySelectorAll('input[id="radiosInDOM"]:checked');
	// 	console.log(
	// 		`%c Number of radios checked true: ${radios.length}`,
	// 		'background: white; color: #000; font-size: 20px'
	// 	);
	// };
	// let contabilidadRadiosChecked = document.querySelectorAll(
	// 	'input[class="contabilidad-radio"]:checked'
	// );
	// let bancosRadiosChecked = document.querySelectorAll(
	// 	'input[class="bancos-radio"]:checked'
	// );

	// let nominaRadiosChecked = document.querySelectorAll(
	// 	'input[class="nomina-radio"]:checked'
	// );

	// let inventariosRadiosChecked = document.querySelectorAll(
	// 	'input[class="inventarios-radio"]:checked'
	// );

  // nuevo codigo comentado
	// let RadiosDiscountChecked = document.querySelectorAll(
	// 	'input[id="discount"]:checked'
	// ) as NodeListOf<HTMLInputElement>;

	// let RadiosNotDiscount = document.querySelectorAll(
	// 	// 'input[id="NOT-discount"]:not(:checked)'
	// 	'input[id="NOT-discount"]:checked'
	// ) as NodeListOf<HTMLInputElement>;

	// console.log(`contabilidad radios checked ${contabilidadRadiosChecked.length}`);

	// console.log(`bancos radios checked ${bancosRadiosChecked.length}`);

	// console.log(`nomina radios checked ${nominaRadiosChecked.length}`);

	// console.log(`inventarios radios checked ${inventariosRadiosChecked.length}`);

	// ?THIS FUNCTION ALLOWS TO PRESERVE DISCOUNT OF PLANS BASICO OR LIGERO WHEN USER SELECTS OTHER PLANS
	/**
	 * It loops through the array of checked radio buttons (RadiosDiscountChecked), and adds up the values of each checked radio
	 * button.
	 * @returns The total of the values of the checked radio buttons.
	 */
  // nuevo codigo comentado
	// const getTotalOfRadiosDiscountCheckedValues = () => {
	// 	let total = 0;
	// 	for (let i = 0; i < RadiosDiscountChecked.length; i++) {
	// 		total += Number(RadiosDiscountChecked[i].value);
	// 	}
	// 	return total;
	// };

	// console.log(`TEST discount radio checked = (N) MODULES SELECTED ${RadiosDiscountChecked.length}`);

	// console.log(`TEST VALUE of radios discount checked =	 ${getTotalOfRadiosDiscountCheckedValues()}`);

	// // radios not discount checked
	// console.log(`TEST NOT discount radio checked =  ${RadiosNotDiscount.length}`);

	const getTotal = (): number => {
		return (
			Number(contabilidad) +
			Number(bancos) +
			Number(nomina) +
			Number(inventarios) +
			Number(cuentasPorCobrar) +
			Number(compras) +
			Number(cuentasPorPagar) +
			Number(puntoDeVenta) +
			Number(ventas) +
			Number(enRuta) +
			Number(syncE) +
			Number(ceoMovil) +
			Number(admSuc) +
			Number(simb) +
      Number(fael)
		);
	};

	//array of html select elements

	const selectMenus = [
		document.getElementById('contabilidadSelect') as HTMLSelectElement | null,
		document.getElementById('bancosSelect') as HTMLSelectElement | null,
		document.getElementById('nominaSelect') as HTMLSelectElement | null,
		document.getElementById('inventariosSelect') as HTMLSelectElement | null,
		document.getElementById('cuentasPorCobrarSelect') as HTMLSelectElement | null,
		document.getElementById('comprasSelect') as HTMLSelectElement | null,
		document.getElementById('cuentasPorPagarSelect') as HTMLSelectElement | null,
		document.getElementById('puntoDeVentaSelect') as HTMLSelectElement | null,
		document.getElementById('ventasSelect') as HTMLSelectElement | null,
		document.getElementById('enRutaSelect') as HTMLSelectElement | null,
		document.getElementById('syncESelect') as HTMLSelectElement | null,
		document.getElementById('ceoMovilSelect') as HTMLSelectElement | null,
		document.getElementById('admSucSelect') as HTMLSelectElement | null,
	];

	const getDiscount = (): number | string => {
		let discountFive = 0.05;
		let discountTen = 0.1;
		let discountFifteen = 0.15;
		let mappedMenus = selectMenus.map((menu) => menu?.selectedIndex);

    // nuevo codigo
    // Mover estas declaraciones dentro de la función
    let RadiosDiscountChecked = document.querySelectorAll(
      'input[id="discount"]:checked:not([name="simb"], [name="fael"])'
    ) as NodeListOf<HTMLInputElement>;

    let RadiosNotDiscount = document.querySelectorAll(
      'input[id="NOT-discount"]:checked:not([name="simb"], [name="fael"])'
    ) as NodeListOf<HTMLInputElement>;

    // Calcular el total descontable excluyendo SIMB
    const getDiscountableTotal = () => {
      return getTotal() - simb - fael;
    };

    const getTotalOfRadiosDiscountCheckedValues = () => {
      let total = 0;
      for (let i = 0; i < RadiosDiscountChecked.length; i++) {
        total += Number(RadiosDiscountChecked[i].value);
      }
      return total;
    };

		// *THIS PART 1 EVALUATES IF PLANS BASICO OR LGIERO ARE SELECTED AND 2, 3, 4, 5 OR MORE MODULES ARE
		// *SELECTED, THEN APPLY DISCOUNT 5% IF 2 MODULES ARE SELECTED, 10% IF 3 OR 4 MODULES ARE SELECTED AND
		// *AND 15% IF 5 OR MORE MODULES ARE SELECTED
		if (RadiosDiscountChecked.length === 1 && RadiosNotDiscount.length === 0) {
			return Number(0);
		} else if (
			RadiosDiscountChecked.length === 2 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.every((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).every((index) => index === 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.every((menu) => menu?.selectedIndex === 0)
		) {
			// return Number(getTotal() * discountFive).toFixed(2);
      return Number(getDiscountableTotal() * discountFive).toFixed(2);
		} else if (
			RadiosDiscountChecked.length === 3 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.every((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).every((index) => index === 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.every((menu) => menu?.selectedIndex === 0)
		) {
			// return Number(getTotal() * discountTen).toFixed(2);
      return Number(getDiscountableTotal() * discountTen).toFixed(2);
		} else if (
			RadiosDiscountChecked.length === 4 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.every((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).every((index) => index === 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.every((menu) => menu?.selectedIndex === 0)
		) {
			// return Number(getTotal() * discountTen).toFixed(2);
      return Number(getDiscountableTotal() * discountTen).toFixed(2);
		} else if (
			RadiosDiscountChecked.length >= 5 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.every((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).every((index) => index === 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.every((menu) => menu?.selectedIndex === 0)
		) {
			// return Number(getTotal() * discountFifteen).toFixed(2);
      return Number(getDiscountableTotal() * discountFifteen).toFixed(2);
			// *END OF PART 1
			// *THIS PART 2 EVALUATES IF PLANS BASICO OR LGIERO ARE SELECTED AND 2, 3, 4, 5 OR MORE MODULES ARE
			// *SELECTED AND AN EXTRA PLAN IS SELECTED, SO IT APPLIES DISCOUNT ONLY TO THE PLANS BASICO OR LIGERO
			// *AND KEEPS SHOWING THE DISCOUNT FOR THE PLANS BASICO OR LIGERO
		} else if (RadiosDiscountChecked.length === 2 && RadiosNotDiscount.length >= 1) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountFive).toFixed(2);
		} else if (RadiosDiscountChecked.length === 3 && RadiosNotDiscount.length >= 1) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountTen).toFixed(2);
		} else if (RadiosDiscountChecked.length === 4 && RadiosNotDiscount.length >= 1) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountTen).toFixed(2);
		} else if (RadiosDiscountChecked.length >= 5 && RadiosNotDiscount.length >= 1) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountFifteen).toFixed(2);
			// *END OF PART 2
			// * THIS PART 3 EVALUATES IF PLANS BASICO OR LIGERO ARE SELECTED, NO EXTRA PLANS SELECTED AND
			// *ONE OPTION FROM SELECT MENU IS ACTIVE, SO IT APPLIES DISCOUNT ONLY TO THE PLANS BASICO OR LIGERO
			// *AND KEEPS SHOWING THE DISCOUNT FOR THE PLANS BASICO OR LIGERO
		} else if (
			RadiosDiscountChecked.length === 2 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.some((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).some((index) => index > 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.some((menu) => menu?.selectedIndex > 0)
		) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountFive).toFixed(2);
		} else if (
			RadiosDiscountChecked.length === 3 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.some((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).some((index) => index > 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.some((menu) => menu?.selectedIndex > 0)
		) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountTen).toFixed(2);
		} else if (
			RadiosDiscountChecked.length === 4 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.some((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).some((index) => index > 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.some((menu) => menu?.selectedIndex > 0)
		) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountTen).toFixed(2);
		} else if (
			RadiosDiscountChecked.length >= 5 &&
			RadiosNotDiscount.length === 0 &&
			mappedMenus.some((index) => index === 0)
			// selectMenus.map((menu) => menu.selectedIndex).some((index) => index > 0)
			// Object.values(selectMenus).every((menu) => menu.selectedIndex === 0)
			// selectMenus.some((menu) => menu?.selectedIndex > 0)
		) {
			return Number(getTotalOfRadiosDiscountCheckedValues() * discountFifteen).toFixed(2);
		} else {
			return Number(0);
			// *END OF PART 3
		}
	};

	return (
		<>
			<div>
				<div className={styles.components}>
					<HeaderModulesOne textTitle="Licencias por número de usuarios por módulo" />
					<TopHeader />
					<BancosT bancos={bancos} handleBancos={handleBancos} />
					<ContabilidadT contabilidad={contabilidad} handleContabilidad={handleContabilidad} />
					<NominaT nomina={nomina} handleNomina={handleNomina} />
					<CEOMovilT ceoMovil={ceoMovil} handleCeoMovil={handleCeoMovil} />
					<CuentasPorPagarT cuentasPorPagar={cuentasPorPagar} handleCuentasPorPagar={handleCuentasPorPagar} />
					<ComprasT compras={compras} handleCompras={handleCompras} />
					<InventariosT inventarios={inventarios} handleInventarios={handleInventarios} />
					<CuentasPorCobrarT
						cuentasPorCobrar={cuentasPorCobrar}
						handleCuentasPorCobrar={handleCuentasPorCobrar}
					/>
					<SyncET syncE={syncE} handleSyncE={handleSyncE} />
					<VentasT ventas={ventas} handleVentas={handleVentas} />
					<PuntoDeVentaT puntoDeVenta={puntoDeVenta} handlePuntoDeVenta={handlePuntoDeVenta} />
					<EnRutaT enRuta={enRuta} handleEnRuta={handleEnRuta} />
					<HeaderModulesOne
						moduleTitle="Otros productos"
						textTitle="Licencias por número de establecimientos (incluyendo matriz)"
					/>
					<AdmSucT admSuc={admSuc} handleAdmSuc={handleAdmSuc} />
          <SimbT simb={simb} handleSimb={handleSimb} />
          <FaelT fael={fael} handleFael={handleFael} />
					<TotalTradicional getTotal={getTotal} getDiscount={getDiscount} />
				</div>

				{/*<div>*/}
				{/*	<p>Sub Total</p>*/}
				{/*	{new Intl.NumberFormat('mx-MX', {*/}
				{/*		style: 'currency',*/}
				{/*		currency: 'MXN',*/}
				{/*	}).format(getTotal())}*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	<p>Descuento Por Volumen</p>*/}
				{/*	{new Intl.NumberFormat('mx-MX', {*/}
				{/*		style: 'currency',*/}
				{/*		currency: 'MXN',*/}
				{/*	}).format(Number(getDiscount()))}*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	<p>Total</p>*/}
				{/*	{new Intl.NumberFormat('mx-MX', {*/}
				{/*		style: 'currency',*/}
				{/*		currency: 'MXN',*/}
				{/*	}).format(getTotal() - Number(getDiscount()))}*/}
				{/*</div>*/}
			</div>
		</>
	);
}
