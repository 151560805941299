import { HeaderModulesOne } from '../components/HeaderModulesOne';
import { SICS } from '../precios-suscripcion/preciosSus';
import { SIMB } from '../precios-suscripcion/preciosSus';
import { FAEL } from '../precios-suscripcion/preciosSus';
import styles from '../styles/tradicional.module.css';
import React, { useState } from 'react';
import { TopHeader } from '../components/TopHeader';
import { ContabilidadS } from '../modulos-suscripcion/ContabilidadS';
import { BancosS } from '../modulos-suscripcion/BancosS';
import { NominaS } from '../modulos-suscripcion/NominaS';
import { InventariosS } from '../modulos-suscripcion/InventariosS';
import { CuentasPorCobrarS } from '../modulos-suscripcion/CuentasPorCobrarS';
import { ComprasS } from '../modulos-suscripcion/ComprasS';
import { CuentasPorPagarS } from '../modulos-suscripcion/CuentasPorPagarS';
import { PuntoDeVentaS } from '../modulos-suscripcion/PuntoDeVentaS';
import { VentasS } from '../modulos-suscripcion/VentasS';
import { EnRutaS } from '../modulos-suscripcion/EnRutaS';
import { SyncES } from '../modulos-suscripcion/SyncES';
import { CEOMovilS } from '../modulos-suscripcion/CEOMovilS';
import { AdmSucS } from '../modulos-suscripcion/AdmSucS';
import { SicsS } from '../modulos-suscripcion/SicsS';
import { SimbS } from '../modulos-suscripcion/SimbS';
import { FaelS } from '../modulos-suscripcion/FaelS';
import { TotalSuscripcion } from '../components/TotalSuscripcion';

export const Suscripcion = () => {
	const [contabilidadSus, setContabilidadSus] = useState<number>(0);
	const [bancosSus, setBancosSus] = useState<number>(0);
	const [nominaSus, setNominaSus] = useState<number>(0);
	const [inventariosSus, setInventariosSus] = useState<number>(0);
	const [cuentasPorCobrarSus, setCuentasPorCobrarSus] = useState<number>(0);
	const [comprasSus, setComprasSus] = useState<number>(0);
	const [cuentasPorPagarSus, setCuentasPorPagarSus] = useState<number>(0);
	const [puntoDeVentaSus, setPuntoDeVentaSus] = useState<number>(0);
	const [ventasSus, setVentasSus] = useState<number>(0);
	const [enRutaSus, setEnRutaSus] = useState<number>(0);
	const [syncESus, setSyncESus] = useState<number>(0);
	const [ceoMovilSus, setCeoMovilSus] = useState<number>(0);
	const [admSucSus, setAdmSucSus] = useState<number>(0);
	const [sicsSus, setSicsSus] = useState<number>(0);
	const [simbSus, setSimbSus] = useState<number>(0);
	const [faelSus, setFaelSus] = useState<number>(0);

	const handleContabilidadSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setContabilidadSus(Number(e.target.value));
		let contabilidadMenu = document.getElementById('contabilidadSelect') as HTMLSelectElement;
		let inputContabilidad = document.querySelectorAll(
			'input[name="contabilidad"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputContabilidad.length >= 1) {
			contabilidadMenu.selectedIndex = 0;
		}
	};

	const handleBancosSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setBancosSus(Number(e.target.value));
		let bancosMenu = document.getElementById('bancosSelect') as HTMLSelectElement;
		let inputBancos = document.querySelectorAll(
			'input[name="bancos"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputBancos.length >= 1) {
			bancosMenu.selectedIndex = 0;
		}
	};

	const handleNominaSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNominaSus(Number(e.target.value));
		let nominaMenu = document.getElementById('nominaSelect') as HTMLSelectElement;
		let inputNomina = document.querySelectorAll(
			'input[name="nomina"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputNomina.length >= 1) {
			nominaMenu.selectedIndex = 0;
		}
	};

	const handleInventariosSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInventariosSus(Number(e.target.value));
		let inventariosMenu = document.getElementById('inventariosSelect') as HTMLSelectElement;
		let inputInventarios = document.querySelectorAll(
			'input[name="inventarios"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputInventarios.length >= 1) {
			inventariosMenu.selectedIndex = 0;
		}
	};

	const handleCuentasPorCobrarSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCuentasPorCobrarSus(Number(e.target.value));
		let cuentasPorCobrarMenu = document.getElementById('cuentasPorCobrarSelect') as HTMLSelectElement;
		let inputCuentasPorCobrar = document.querySelectorAll(
			'input[name="cuentasPorCobrar"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCuentasPorCobrar.length >= 1) {
			cuentasPorCobrarMenu.selectedIndex = 0;
		}
	};

	const handleComprasSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setComprasSus(Number(e.target.value));
		let comprasMenu = document.getElementById('comprasSelect') as HTMLSelectElement;
		let inputCompras = document.querySelectorAll(
			'input[name="compras"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCompras.length >= 1) {
			comprasMenu.selectedIndex = 0;
		}
	};

	const handleCuentasPorPagarSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCuentasPorPagarSus(Number(e.target.value));
		let cuentasPorPagarMenu = document.getElementById('cuentasPorPagarSelect') as HTMLSelectElement;
		let inputCuentasPorPagar = document.querySelectorAll(
			'input[name="cuentasPorPagar"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCuentasPorPagar.length >= 1) {
			cuentasPorPagarMenu.selectedIndex = 0;
		}
	};

	const handlePuntoDeVentaSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPuntoDeVentaSus(Number(e.target.value));
		let puntoDeVentaMenu = document.getElementById('puntoDeVentaSelect') as HTMLSelectElement;
		let inputPuntoDeVenta = document.querySelectorAll(
			'input[name="puntoDeVenta"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputPuntoDeVenta.length >= 1) {
			puntoDeVentaMenu.selectedIndex = 0;
		}
	};

	const handleVentasSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVentasSus(Number(e.target.value));
		let ventasMenu = document.getElementById('ventasSelect') as HTMLSelectElement;
		let inputVentas = document.querySelectorAll(
			'input[name="ventas"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputVentas.length >= 1) {
			ventasMenu.selectedIndex = 0;
		}
	};

	const handleEnRutaSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEnRutaSus(Number(e.target.value));
		let enRutaMenu = document.getElementById('enRutaSelect') as HTMLSelectElement;
		let inputEnRuta = document.querySelectorAll(
			'input[name="enRuta"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputEnRuta.length >= 1) {
			enRutaMenu.selectedIndex = 0;
		}
	};

	const handleSyncESus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSyncESus(Number(e.target.value));
		let syncEMenu = document.getElementById('syncESelect') as HTMLSelectElement;
		let inputSyncE = document.querySelectorAll('input[name="syncE"]:checked') as NodeListOf<HTMLInputElement>;
		if (inputSyncE.length >= 1) {
			syncEMenu.selectedIndex = 0;
		}
	};

	const handleCeoMovilSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCeoMovilSus(Number(e.target.value));
		let ceoMovilMenu = document.getElementById('ceoMovilSelect') as HTMLSelectElement;
		let inputCeoMovil = document.querySelectorAll(
			'input[name="ceoMovil"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputCeoMovil.length >= 1) {
			ceoMovilMenu.selectedIndex = 0;
		}
	};

	const handleAdmSucSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdmSucSus(Number(e.target.value));
		let admSucMenu = document.getElementById('admSucSelect') as HTMLSelectElement;
		let inputAdmSuc = document.querySelectorAll(
			'input[name="admSuc"]:checked'
		) as NodeListOf<HTMLInputElement>;
		if (inputAdmSuc.length >= 1) {
			admSucMenu.selectedIndex = 0;
		}
	};

	const handleSicsSus = (e: React.ChangeEvent<HTMLInputElement>) => {
		// only positive numbers
		if (Number(e.target.value) >= 0) {
			setSicsSus(Number(e.target.value) * SICS.individual);
		} else {
			setSicsSus(0);
		}
		// setSicsSus(Number(e.target.value) * SICS.individual);
	};

  const handleSimbSus = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only positive numbers
    if (Number(e.target.value) >= 0) {
      setSimbSus(Number(e.target.value) * SIMB.individual);
    } else {
      setSimbSus(0);
    }
    // setSicsSus(Number(e.target.value) * SICS.individual);
  };

  const handleFaelSus = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only positive numbers
    // if (Number(e.target.value) >= 0) {
    //   setFaelSus(Number(e.target.value) * FAEL.individual);
    // } else {
    //   setFaelSus(0);
    // }
    // setSicsSus(Number(e.target.value) * SICS.individual);
    const value = parseInt(e.target.value, 10);
    if (value === 1) {
      setFaelSus(FAEL.individual);
    } else {
      setFaelSus(0);
      e.target.value = '0';
    }
  };


	let RadiosDiscountChecked = document.querySelectorAll(
		'input[id="discount"]:checked'
	) as NodeListOf<HTMLInputElement>;

	let RadiosNotDiscount = document.querySelectorAll(
		// 'input[id="NOT-discount"]:not(:checked)'
		'input[id="NOT-discount"]:checked'
	) as NodeListOf<HTMLInputElement>;

	const getTotalOfRadiosDiscountCheckedValues = () => {
		let total = 0;
		for (let i = 0; i < RadiosDiscountChecked.length; i++) {
			total += Number(RadiosDiscountChecked[i].value);
		}
		return total;
	};

	const getSubTotalSus = (): number => {
		return (
			Number(contabilidadSus) +
			Number(bancosSus) +
			Number(nominaSus) +
			Number(inventariosSus) +
			Number(cuentasPorCobrarSus) +
			Number(comprasSus) +
			Number(cuentasPorPagarSus) +
			Number(puntoDeVentaSus) +
			Number(ventasSus) +
			Number(enRutaSus) +
			Number(syncESus) +
			Number(ceoMovilSus) +
			Number(admSucSus) +
			Number(simbSus) +
			Number(faelSus)
		);
		// Number(sicsSus)
	};

	const selectMenus = [
		document.getElementById('contabilidadSelect') as HTMLSelectElement | null,
		document.getElementById('bancosSelect') as HTMLSelectElement | null,
		document.getElementById('nominaSelect') as HTMLSelectElement | null,
		document.getElementById('inventariosSelect') as HTMLSelectElement | null,
		document.getElementById('cuentasPorCobrarSelect') as HTMLSelectElement | null,
		document.getElementById('comprasSelect') as HTMLSelectElement | null,
		document.getElementById('cuentasPorPagarSelect') as HTMLSelectElement | null,
		document.getElementById('puntoDeVentaSelect') as HTMLSelectElement | null,
		document.getElementById('ventasSelect') as HTMLSelectElement | null,
		document.getElementById('enRutaSelect') as HTMLSelectElement | null,
		document.getElementById('syncESelect') as HTMLSelectElement | null,
		document.getElementById('ceoMovilSelect') as HTMLSelectElement | null,
		document.getElementById('admSucSelect') as HTMLSelectElement | null,
	];

	// const getDiscountSus = (): number => {
	// 	let discountTen = 0.1;
	// 	let discountFifteen = 0.15;
	// 	let discountTwenty = 0.2;
	// 	let mappedMenus = selectMenus.map((menu) => menu?.selectedIndex);
	// 	//	store all selected indexes of mappedMenus
	// 	let selectedIndexes = mappedMenus.filter((index) => index !== 0);
	// 	// //TODO 1 add a universal class to all module inputs
	// 	let myInputs = document.querySelectorAll(
	// 		'input[class="inputSus"]:checked'
	// 	) as NodeListOf<HTMLInputElement>;

	// 	if (selectedIndexes.length + myInputs.length === 2) {
	// 		return discountTen * getSubTotalSus();
	// 	} else if (selectedIndexes.length + myInputs.length === 3) {
	// 		return discountFifteen * getSubTotalSus();
	// 	} else if (selectedIndexes.length + myInputs.length === 4) {
	// 		return discountFifteen * getSubTotalSus();
	// 	} else if (selectedIndexes.length + myInputs.length >= 5) {
	// 		return discountTwenty * getSubTotalSus();
	// 	} else {
	// 		return 0;
	// 	}
	// };

  const getDiscountSus = (): number => {
    let discountTen = 0.1;
    let discountFifteen = 0.15;
    let discountTwenty = 0.2;
    let mappedMenus = selectMenus.map((menu) => menu?.selectedIndex);
    let selectedIndexes = mappedMenus.filter((index) => index !== 0);

    // Excluir FAEL y SIMB del conteo
    let myInputs = document.querySelectorAll(
      'input[class="inputSus"]:checked:not([name="fael"]):not([name="simb"])'
    ) as NodeListOf<HTMLInputElement>;

    let totalModules = selectedIndexes.length + myInputs.length;

    // Calcular el subtotal excluyendo FAEL y SIMB
    let discountableSubtotal = getSubTotalSus() - faelSus - simbSus;

    if (totalModules === 2) {
      return discountTen * discountableSubtotal;
    } else if (totalModules === 3 || totalModules === 4) {
      return discountFifteen * discountableSubtotal;
    } else if (totalModules >= 5) {
      return discountTwenty * discountableSubtotal;
    } else {
      return 0;
    }
  };

	const getTotalSus = (): number => {
		return getSubTotalSus() + Number(sicsSus);
	};

	return (
		<div className={styles.components}>
			<HeaderModulesOne textTitle="Licencias por número de usuarios por módulo" />
			<TopHeader />
			<BancosS bancosSus={bancosSus} handleBancosSus={handleBancosSus} />
			<ContabilidadS contabilidadSus={contabilidadSus} handleContabilidadSus={handleContabilidadSus} />
			<NominaS nominaSus={nominaSus} handleNominaSus={handleNominaSus} />
			<CEOMovilS ceoMovilSus={ceoMovilSus} handleCeoMovilSus={handleCeoMovilSus} />
			<CuentasPorPagarS
				cuentasPorPagarSus={cuentasPorPagarSus}
				handleCuentasPorPagarSus={handleCuentasPorPagarSus}
			/>
			<ComprasS comprasSus={comprasSus} handleComprasSus={handleComprasSus} />
			<InventariosS inventariosSus={inventariosSus} handleInventariosSus={handleInventariosSus} />
			<CuentasPorCobrarS
				cuentasPorCobrarSus={cuentasPorCobrarSus}
				handleCuentasPorCobrarSus={handleCuentasPorCobrarSus}
			/>
			<SyncES syncESus={syncESus} handleSyncESus={handleSyncESus} />
			<VentasS ventasSus={ventasSus} handleVentasSus={handleVentasSus} />
			<PuntoDeVentaS puntoDeVentaSus={puntoDeVentaSus} handlePuntoDeVentaSus={handlePuntoDeVentaSus} />
			<EnRutaS enRutaSus={enRutaSus} handleEnRutaSus={handleEnRutaSus} />
			<HeaderModulesOne
				moduleTitle="Otros productos"
				textTitle="Licencias por número de establecimientos (incluyendo matriz)"
			/>
			<AdmSucS admSucSus={admSucSus} handleAdmSucSus={handleAdmSucSus} />
			<SicsS sicsSus={sicsSus} handleSicsSus={handleSicsSus} />
			<SimbS simbSus={simbSus} handleSimbSus={handleSimbSus} />
			<FaelS faelSus={faelSus} handleFaelSus={handleFaelSus} />
			<TotalSuscripcion getTotalSus={getTotalSus} getDiscountSus={getDiscountSus} />
		</div>
	);
};
