import styles from '../styles/TopHeader.module.css';
import React from 'react';

export const TopHeader = () => {
	return (
		<>
			<div className={styles.component}>
				<div className={styles.gridGroup}>
					<div>Módulos</div>

					<div>No agregar</div>
					<div>Básico (1)</div>
					<div>Ligero (2)</div>
					<div>Profesional (3)</div>
					<div>Premium (5)</div>
					<div>Corporativo (10)</div>
					<div>Usuarios (15+)</div>
					<div>Total</div>
				</div>
			</div>
		</>
	);
};
