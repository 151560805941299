export const Home = () => {
	return (
		<div>
			<h1
				style={{
					color: '#ff8623',
					textAlign: 'center',
					fontSize: '2rem',
					padding: '1rem',
					// reveal text on first load
				}}
			>
				Bienvenido al cotizador Microsip
			</h1>
			<p
				style={{
					color: '#202427',
					textAlign: 'center',
					fontSize: '1.5rem',
					padding: '0.5rem',
				}}
			>
				Por favor seleccione la modalidad de pago, en Tradicional o en Suscripción.
			</p>
		</div>
	);
};
