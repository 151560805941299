import { Inventarios } from '../precios-suscripcion/preciosSus';
import React from 'react';
import '../styles/moduleT.css';

interface InventariosProps {
	inventariosSus: number;
	handleInventariosSus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InventariosS = (props: InventariosProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleInventariosSus}>
					<div className="module-box">Inventarios</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={Inventarios.noAgregar}
								type="radio"
								name="inventarios"
								id="inventarios-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								// className="inventarios-radio "
								className="inputSus"
								value={Inventarios.basico}
								type="radio"
								name="inventarios"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								// className="inventarios-radio "
								className="inputSus"
								value={Inventarios.ligero}
								type="radio"
								name="inventarios"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								// className="inventarios-radio "
								className="inputSus"
								value={Inventarios.profesional}
								type="radio"
								name="inventarios"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								// className="inventarios-radio "
								className="inputSus"
								value={Inventarios.premium}
								type="radio"
								name="inventarios"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								// className="inventarios-radio "
								className="inputSus"
								value={Inventarios.corporativo}
								type="radio"
								name="inventarios"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputInventarios = document.querySelectorAll(
									'input[name="inventarios"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputInventarios.forEach((input) => {
									input.checked = false;
								});

								// props.handleNomina();
							}}
							name="inventarios"
							id="inventariosSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={Inventarios.incremento1}>15</option>
							<option value={Inventarios.incremento2}>20</option>
							<option value={Inventarios.incremento3}>25</option>
							<option value={Inventarios.incremento4}>30</option>
							<option value={Inventarios.incremento5}>35</option>
							<option value={Inventarios.incremento6}>40</option>
							<option value={Inventarios.incremento7}>45</option>
							<option value={Inventarios.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.inventariosSus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.inventariosSus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
