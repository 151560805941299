import { SyncE } from '../precios-suscripcion/preciosSus';
import React from 'react';
import '../styles/moduleT.css';

interface SyncEProps {
	syncESus: number;
	handleSyncESus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SyncES = (props: SyncEProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleSyncESus}>
					<div className="module-box">Sync-E</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={SyncE.noAgregar}
								type="radio"
								name="syncE"
								id="syncE-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								// className="syncE-radio "
								className="inputSus"
								value={SyncE.basico}
								type="radio"
								name="syncE"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								// className="syncE-radio "
								className="inputSus"
								value={SyncE.ligero}
								type="radio"
								name="syncE"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								// className="syncE-radio "
								className="inputSus"
								value={SyncE.profesional}
								type="radio"
								name="syncE"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								// className="syncE-radio "
								className="inputSus"
								value={SyncE.premium}
								type="radio"
								name="syncE"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								// className="syncE-radio "
								className="inputSus"
								value={SyncE.corporativo}
								type="radio"
								name="syncE"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputSyncE = document.querySelectorAll(
									'input[name="syncE"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputSyncE.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="syncE"
							id="syncESelect"
						>
							<option value="">Por Usuarios</option>
							<option value={SyncE.incremento1}>15</option>
							<option value={SyncE.incremento2}>20</option>
							<option value={SyncE.incremento3}>25</option>
							<option value={SyncE.incremento4}>30</option>
							<option value={SyncE.incremento5}>35</option>
							<option value={SyncE.incremento6}>40</option>
							<option value={SyncE.incremento7}>45</option>
							<option value={SyncE.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.syncESus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.syncESus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
