import Logo from '../assets/logo-microsip.png';
import styles from './Banner.module.css';
export const Banner = () => {
	return (
		<>
			<div>
				<div className={styles.gridImage}>
					<img src={Logo} alt="Logo" width={160} />
				</div>
				<div className={styles.title}>
					<h1>Lista de precios 2024</h1>
				</div>
				<div className={styles.linkContainer}>
					<div className={styles.linkBtn}>
						<a
              href="https://cdn.prod.website-files.com/62ebdd458759bdb749120f22/66c4bf5d0060781cb0c2df40_Listas%20de%20precios%202024_ajuste_14_Ago.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Descargar Lista de Precios
						</a>
					</div>
				</div>
			</div>
		</>
	);
};
