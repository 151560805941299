interface ModuleTypes {
	id: number;
	noAgregar: number;
	basico: number;
	ligero: number;
	profesional: number;
	premium: number;
	corporativo: number;
	incremento: number;
	incremento1: number;
	incremento2: number;
	incremento3: number;
	incremento4: number;
	incremento5: number;
	incremento6: number;
	incremento7: number;
	incremento8: number;
}

export const Contabilidad: ModuleTypes = {
  id: 1,
  noAgregar: 0,
  basico: 7500,
  ligero: 9768,
  profesional: 12264,
  premium: 17691,
  corporativo: 36554,
  incremento: 17262,
  incremento1: 36554 + 17262,
  incremento2: 36554 + 17262 * 2,
  incremento3: 36554 + 17262 * 3,
  incremento4: 36554 + 17262 * 4,
  incremento5: 36554 + 17262 * 5,
  incremento6: 36554 + 17262 * 6,
  incremento7: 36554 + 17262 * 7,
  incremento8: 36554 + 17262 * 8,
};

export const Bancos: ModuleTypes = {
  id: 2,
  noAgregar: 0,
  basico: 7500,
  ligero: 9768,
  profesional: 12264,
  premium: 17691,
  corporativo: 36554,
  incremento: 17262,
  incremento1: 36554 + 17262,
  incremento2: 36554 + 17262 * 2,
  incremento3: 36554 + 17262 * 3,
  incremento4: 36554 + 17262 * 4,
  incremento5: 36554 + 17262 * 5,
  incremento6: 36554 + 17262 * 6,
  incremento7: 36554 + 17262 * 7,
  incremento8: 36554 + 17262 * 8,
};

export const Nomina: ModuleTypes = {
  id: 3,
  noAgregar: 0,
  basico: 7950,
  ligero: 10494,
  profesional: 13038,
  premium: 18773,
  corporativo: 37708,
  incremento: 18661,
  incremento1: 37708 + 18661,
  incremento2: 37708 + 18661 * 2,
  incremento3: 37708 + 18661 * 3,
  incremento4: 37708 + 18661 * 4,
  incremento5: 37708 + 18661 * 5,
  incremento6: 37708 + 18661 * 6,
  incremento7: 37708 + 18661 * 7,
  incremento8: 37708 + 18661 * 8,
};

export const Inventarios: ModuleTypes = {
  id: 4,
  noAgregar: 0,
  basico: 3514,
  ligero: 5597,
  profesional: 7515,
  premium: 12243,
  corporativo: 29950,
  incremento: 11570,
  incremento1: 29950 + 11570,
  incremento2: 29950 + 11570 * 2,
  incremento3: 29950 + 11570 * 3,
  incremento4: 29950 + 11570 * 4,
  incremento5: 29950 + 11570 * 5,
  incremento6: 29950 + 11570 * 6,
  incremento7: 29950 + 11570 * 7,
  incremento8: 29950 + 11570 * 8,
};

export const CuentasPorCobrar: ModuleTypes = {
  id: 5,
  noAgregar: 0,
  basico: 3514,
  ligero: 5597,
  profesional: 7515,
  premium: 12243,
  corporativo: 29950,
  incremento: 11570,
  incremento1: 29950 + 11570,
  incremento2: 29950 + 11570 * 2,
  incremento3: 29950 + 11570 * 3,
  incremento4: 29950 + 11570 * 4,
  incremento5: 29950 + 11570 * 5,
  incremento6: 29950 + 11570 * 6,
  incremento7: 29950 + 11570 * 7,
  incremento8: 29950 + 11570 * 8,
};

export const Compras: ModuleTypes = {
  id: 6,
  noAgregar: 0,
  basico: 4367,
  ligero: 7977,
  profesional: 9980,
  premium: 14347,
  corporativo: 33220,
  incremento: 13957,
  incremento1: 33220 + 13957,
  incremento2: 33220 + 13957 * 2,
  incremento3: 33220 + 13957 * 3,
  incremento4: 33220 + 13957 * 4,
  incremento5: 33220 + 13957 * 5,
  incremento6: 33220 + 13957 * 6,
  incremento7: 33220 + 13957 * 7,
  incremento8: 33220 + 13957 * 8,
};

export const CuentasPorPagar: ModuleTypes = {
  id: 7,
  noAgregar: 0,
  basico: 3514,
  ligero: 5597,
  profesional: 7515,
  premium: 12243,
  corporativo: 29950,
  incremento: 11570,
  incremento1: 29950 + 11570,
  incremento2: 29950 + 11570 * 2,
  incremento3: 29950 + 11570 * 3,
  incremento4: 29950 + 11570 * 4,
  incremento5: 29950 + 11570 * 5,
  incremento6: 29950 + 11570 * 6,
  incremento7: 29950 + 11570 * 7,
  incremento8: 29950 + 11570 * 8,
};

export const PuntoDeVenta: ModuleTypes = {
  id: 8,
  noAgregar: 0,
  basico: 5549,
  ligero: 9768,
  profesional: 13902,
  premium: 22186,
  corporativo: 44637,
  incremento: 20996,
  incremento1: 44637 + 20996,
  incremento2: 44637 + 20996 * 2,
  incremento3: 44637 + 20996 * 3,
  incremento4: 44637 + 20996 * 4,
  incremento5: 44637 + 20996 * 5,
  incremento6: 44637 + 20996 * 6,
  incremento7: 44637 + 20996 * 7,
  incremento8: 44637 + 20996 * 8,
};

export const Ventas: ModuleTypes = {
  id: 9,
  noAgregar: 0,
  basico: 4367,
  ligero: 7977,
  profesional: 9980,
  premium: 14347,
  corporativo: 33220,
  incremento: 13957,
  incremento1: 33220 + 13957,
  incremento2: 33220 + 13957 * 2,
  incremento3: 33220 + 13957 * 3,
  incremento4: 33220 + 13957 * 4,
  incremento5: 33220 + 13957 * 5,
  incremento6: 33220 + 13957 * 6,
  incremento7: 33220 + 13957 * 7,
  incremento8: 33220 + 13957 * 8,
};

export const EnRuta: ModuleTypes = {
  id: 10,
  noAgregar: 0,
  basico: 0,
  ligero: 7800,
  profesional: 10500,
  premium: 16000,
  corporativo: 32000,
  incremento: 16000,
  incremento1: 32000 + 16000,
  incremento2: 32000 + 16000 * 2,
  incremento3: 32000 + 16000 * 3,
  incremento4: 32000 + 16000 * 4,
  incremento5: 32000 + 16000 * 5,
  incremento6: 32000 + 16000 * 6,
  incremento7: 32000 + 16000 * 7,
  incremento8: 32000 + 16000 * 8,
};

export const SyncE: ModuleTypes = {
  id: 11,
  noAgregar: 0,
  basico: 5250,
  ligero: 8400,
  profesional: 9800,
  premium: 12600,
  corporativo: 21000,
  incremento: 12600,
  incremento1: 21000 + 12600,
  incremento2: 21000 + 12600 * 2,
  incremento3: 21000 + 12600 * 3,
  incremento4: 21000 + 12600 * 4,
  incremento5: 21000 + 12600 * 5,
  incremento6: 21000 + 12600 * 6,
  incremento7: 21000 + 12600 * 7,
  incremento8: 21000 + 12600 * 8,
};

export const CEOmovil: ModuleTypes = {
  id: 12,
  noAgregar: 0,
  basico: 0,
  ligero: 6300,
  profesional: 8500,
  premium: 12900,
  corporativo: 19600,
  incremento: 9800,
  incremento1: 19600 + 9800,
  incremento2: 19600 + 9800 * 2,
  incremento3: 19600 + 9800 * 3,
  incremento4: 19600 + 9800 * 4,
  incremento5: 19600 + 9800 * 5,
  incremento6: 19600 + 9800 * 6,
  incremento7: 19600 + 9800 * 7,
  incremento8: 19600 + 9800 * 8,
};

export const AdministradorDeSucursales: ModuleTypes = {
  id: 13,
  noAgregar: 0,
  basico: 0,
  ligero: 4500,
  profesional: 6300,
  premium: 9000,
  corporativo: 18000,
  incremento: 9000,
  incremento1: 18000 + 9000,
  incremento2: 18000 + 9000 * 2,
  incremento3: 18000 + 9000 * 3,
  incremento4: 18000 + 9000 * 4,
  incremento5: 18000 + 9000 * 5,
  incremento6: 18000 + 9000 * 6,
  incremento7: 18000 + 9000 * 7,
  incremento8: 18000 + 9000 * 8,
};

export const SICS = {
  id: 13,
  noAgregar: 0,
  individual: 260,
};

export const SIMB = {
  id: 14,
  noAgregar: 0,
  individual: 400,
};

export const FAEL = {
  id: 15,
  noAgregar: 0,
  individual: 1000,
};

export const AllModules: ModuleTypes[] = [
	Contabilidad,
	Bancos,
	Nomina,
	Inventarios,
	CuentasPorCobrar,
	Compras,
	CuentasPorPagar,
	PuntoDeVenta,
	Ventas,
	EnRuta,
	SyncE,
	CEOmovil,
	AdministradorDeSucursales,
];
