import { CuentasPorCobrar } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface CuentasPorCobrarProps {
	cuentasPorCobrar: number;
	handleCuentasPorCobrar: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CuentasPorCobrarT = (props: CuentasPorCobrarProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleCuentasPorCobrar}>
					<div className="module-box">Cuentas Por Cobrar</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={CuentasPorCobrar.noAgregar}
								type="radio"
								name="cuentasPorCobrar"
								id="cuentasPorCobrar-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								className="cuentasPorCobrar-radio"
								value={CuentasPorCobrar.basico}
								type="radio"
								name="cuentasPorCobrar"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								className="cuentasPorCobrar-radio"
								value={CuentasPorCobrar.ligero}
								type="radio"
								name="cuentasPorCobrar"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								className="cuentasPorCobrar-radio"
								value={CuentasPorCobrar.profesional}
								type="radio"
								name="cuentasPorCobrar"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								className="cuentasPorCobrar-radio"
								value={CuentasPorCobrar.premium}
								type="radio"
								name="cuentasPorCobrar"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								className="cuentasPorCobrar-radio"
								value={CuentasPorCobrar.corporativo}
								type="radio"
								name="cuentasPorCobrar"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputCuentasPorCobrar = document.querySelectorAll(
									'input[name="cuentasPorCobrar"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputCuentasPorCobrar.forEach((input) => {
									input.checked = false;
								});

								// props.handleNomina();
							}}
							name="cuentasPorCobrar"
							id="cuentasPorCobrarSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={CuentasPorCobrar.incremento1}>15</option>
							<option value={CuentasPorCobrar.incremento2}>20</option>
							<option value={CuentasPorCobrar.incremento3}>25</option>
							<option value={CuentasPorCobrar.incremento4}>30</option>
							<option value={CuentasPorCobrar.incremento5}>35</option>
							<option value={CuentasPorCobrar.incremento6}>40</option>
							<option value={CuentasPorCobrar.incremento7}>45</option>
							<option value={CuentasPorCobrar.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.cuentasPorCobrar === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.cuentasPorCobrar)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
