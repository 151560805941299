import { Nomina } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface NominaProps {
	nomina: number;
	handleNomina: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NominaT = (props: NominaProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleNomina}>
					<div className="module-box">Nómina</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={Nomina.noAgregar}
								type="radio"
								name="nomina"
								id="nomina-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								className="nomina-radio"
								value={Nomina.basico}
								type="radio"
								name="nomina"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								className="nomina-radio"
								value={Nomina.ligero}
								type="radio"
								name="nomina"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								className="nomina-radio"
								value={Nomina.profesional}
								type="radio"
								name="nomina"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								className="nomina-radio"
								value={Nomina.premium}
								type="radio"
								name="nomina"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								className="nomina-radio"
								value={Nomina.corporativo}
								type="radio"
								name="nomina"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputNomina = document.querySelectorAll(
									'input[name="nomina"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputNomina.forEach((input) => {
									input.checked = false;
								});

								// props.handleNomina();
							}}
							name="nomina"
							id="nominaSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={Nomina.incremento1}>15</option>
							<option value={Nomina.incremento2}>20</option>
							<option value={Nomina.incremento3}>25</option>
							<option value={Nomina.incremento4}>30</option>
							<option value={Nomina.incremento5}>35</option>
							<option value={Nomina.incremento6}>40</option>
							<option value={Nomina.incremento7}>45</option>
							<option value={Nomina.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.nomina === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.nomina)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
