import { Bancos } from '../precios-suscripcion/preciosSus';
import React from 'react';
import '../styles/moduleT.css';

interface BancosProps {
	bancosSus: number;
	handleBancosSus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BancosS = (props: BancosProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleBancosSus}>
					<div className="module-box">Bancos</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={Bancos.noAgregar}
								type="radio"
								name="bancos"
								id="bancos-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								// className="contabilidad-radio "
								className="inputSus"
								value={Bancos.basico}
								type="radio"
								name="bancos"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								// className="bancos-radio "
								className="inputSus"
								value={Bancos.ligero}
								type="radio"
								name="bancos"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								// className="bancos-radio "
								className="inputSus"
								value={Bancos.profesional}
								type="radio"
								name="bancos"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								// className="bancos-radio "
								className="inputSus"
								value={Bancos.premium}
								type="radio"
								name="bancos"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								// className="bancos-radio "
								className="inputSus"
								value={Bancos.corporativo}
								type="radio"
								name="bancos"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputBancos = document.querySelectorAll(
									'input[name="bancos"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputBancos.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="bancos"
							id="bancosSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={Bancos.incremento1}>15</option>
							<option value={Bancos.incremento2}>20</option>
							<option value={Bancos.incremento3}>25</option>
							<option value={Bancos.incremento4}>30</option>
							<option value={Bancos.incremento5}>35</option>
							<option value={Bancos.incremento6}>40</option>
							<option value={Bancos.incremento7}>45</option>
							<option value={Bancos.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.bancosSus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.bancosSus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
