import { CEOmovil } from '../precios-suscripcion/preciosSus';
import React from 'react';
import '../styles/moduleT.css';

interface CEOMovilProps {
	ceoMovilSus: number;
	handleCeoMovilSus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CEOMovilS = (props: CEOMovilProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleCeoMovilSus}>
					<div className="module-box">CEO Móvil</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={CEOmovil.noAgregar}
								type="radio"
								name="ceoMovil"
								id="ceoMovil-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span style={{ display: 'none' }}>Básico 1</span>
							<input
								// className="ceoMovil-radio "
								className="inputSus"
								value={CEOmovil.basico}
								type="radio"
								name="ceoMovil"
								id="discount"
								disabled={true}
								style={{ display: 'none' }}
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								// className="ceoMovil-radio "
								className="inputSus"
								value={CEOmovil.ligero}
								type="radio"
								name="ceoMovil"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								// className="ceoMovil-radio "
								className="inputSus"
								value={CEOmovil.profesional}
								type="radio"
								name="ceoMovil"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								// className="ceoMovil-radio "
								className="inputSus"
								value={CEOmovil.premium}
								type="radio"
								name="ceoMovil"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								// className="ceoMovil-radio "
								className="inputSus"
								value={CEOmovil.corporativo}
								type="radio"
								name="ceoMovil"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputCeoMovil = document.querySelectorAll(
									'input[name="ceoMovil"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputCeoMovil.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="ceoMovil"
							id="ceoMovilSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={CEOmovil.incremento1}>15</option>
							<option value={CEOmovil.incremento2}>20</option>
							<option value={CEOmovil.incremento3}>25</option>
							<option value={CEOmovil.incremento4}>30</option>
							<option value={CEOmovil.incremento5}>35</option>
							<option value={CEOmovil.incremento6}>40</option>
							<option value={CEOmovil.incremento7}>45</option>
							<option value={CEOmovil.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.ceoMovilSus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.ceoMovilSus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
