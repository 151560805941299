import { Ventas } from '../precios-suscripcion/preciosSus';
import React from 'react';
import '../styles/moduleT.css';

interface VentasProps {
	ventasSus: number;
	handleVentasSus: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const VentasS = (props: VentasProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleVentasSus}>
					<div className="module-box">Ventas</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={Ventas.noAgregar}
								type="radio"
								name="ventas"
								id="ventas-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								// className="ventas-radio "
								className="inputSus"
								value={Ventas.basico}
								type="radio"
								name="ventas"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								// className="ventas-radio "
								className="inputSus"
								value={Ventas.ligero}
								type="radio"
								name="ventas"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								// className="ventas-radio "
								className="inputSus"
								value={Ventas.profesional}
								type="radio"
								name="ventas"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								// className="ventas-radio "
								className="inputSus"
								value={Ventas.premium}
								type="radio"
								name="ventas"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								// className="ventas-radio "
								className="inputSus"
								value={Ventas.corporativo}
								type="radio"
								name="ventas"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputVentas = document.querySelectorAll(
									'input[name="ventas"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputVentas.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="ventas"
							id="ventasSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={Ventas.incremento1}>15</option>
							<option value={Ventas.incremento2}>20</option>
							<option value={Ventas.incremento3}>25</option>
							<option value={Ventas.incremento4}>30</option>
							<option value={Ventas.incremento5}>35</option>
							<option value={Ventas.incremento6}>40</option>
							<option value={Ventas.incremento7}>45</option>
							<option value={Ventas.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.ventasSus === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.ventasSus)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
