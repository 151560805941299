import { Contabilidad } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface ContabilidadProps {
	contabilidad: number;
	handleContabilidad: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ContabilidadT = (props: ContabilidadProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleContabilidad}>
					<div className="module-box">Contabilidad</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={Contabilidad.noAgregar}
								type="radio"
								name="contabilidad"
								id="contabilidad-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Básico 1</span>
							<input
								className="contabilidad-radio"
								value={Contabilidad.basico}
								type="radio"
								name="contabilidad"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								className="contabilidad-radio"
								value={Contabilidad.ligero}
								type="radio"
								name="contabilidad"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								className="contabilidad-radio"
								value={Contabilidad.profesional}
								type="radio"
								name="contabilidad"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								className="contabilidad-radio"
								value={Contabilidad.premium}
								type="radio"
								name="contabilidad"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								className="contabilidad-radio"
								value={Contabilidad.corporativo}
								type="radio"
								name="contabilidad"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputContabilidad = document.querySelectorAll(
									'input[name="contabilidad"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputContabilidad.forEach((input) => {
									input.checked = false;
								});
								// props.handleContabilidad();
							}}
							name="contabilidad"
							id="contabilidadSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={Contabilidad.incremento1}>15</option>
							<option value={Contabilidad.incremento2}>20</option>
							<option value={Contabilidad.incremento3}>25</option>
							<option value={Contabilidad.incremento4}>30</option>
							<option value={Contabilidad.incremento5}>35</option>
							<option value={Contabilidad.incremento6}>40</option>
							<option value={Contabilidad.incremento7}>45</option>
							<option value={Contabilidad.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.contabilidad === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.contabilidad)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
