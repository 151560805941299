import { EnRuta } from '../precios-tradicional/precios';
import React from 'react';
import '../styles/moduleT.css';

interface EnRutaProps {
	enRuta: number;
	handleEnRuta: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EnRutaT = (props: EnRutaProps) => {
	return (
		<>
			<div className="component">
				<div className="grid-group" onChange={props.handleEnRuta}>
					<div className="module-box">En Ruta</div>

					<div className="radio-box">
						<label className="radio-label">
							<span>No agregar</span>
							<input
								className=""
								value={EnRuta.noAgregar}
								type="radio"
								name="enRuta"
								id="enRuta-noAgregar"
								defaultChecked
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span style={{ display: 'none' }}>Básico 1</span>
							<input
								className="enRuta-radio"
								value={EnRuta.basico}
								type="radio"
								name="enRuta"
								id="discount"
								disabled={true}
								style={{ display: 'none' }}
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Ligero 2</span>
							<input
								className="enRuta-radio"
								value={EnRuta.ligero}
								type="radio"
								name="enRuta"
								id="discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Profesional 3</span>
							<input
								className="enRuta-radio"
								value={EnRuta.profesional}
								type="radio"
								name="enRuta"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Premium 5</span>
							<input
								className="enRuta-radio"
								value={EnRuta.premium}
								type="radio"
								name="enRuta"
								id="NOT-discount"
							/>
						</label>
					</div>
					<div className="radio-box">
						<label className="radio-label">
							<span>Corporativo 10</span>
							<input
								className="enRuta-radio"
								value={EnRuta.corporativo}
								type="radio"
								name="enRuta"
								id="NOT-discount"
							/>
						</label>
					</div>

					<div className="select-box">
						<select
							className="select"
							onChange={() => {
								let grabInputEnRuta = document.querySelectorAll(
									'input[name="enRuta"]'
								) as NodeListOf<HTMLInputElement>;

								grabInputEnRuta.forEach((input) => {
									input.checked = false;
								});
								// props.handleBancos();
							}}
							name="enRuta"
							id="enRutaSelect"
						>
							<option value="">Por Usuarios</option>
							<option value={EnRuta.incremento1}>15</option>
							<option value={EnRuta.incremento2}>20</option>
							<option value={EnRuta.incremento3}>25</option>
							<option value={EnRuta.incremento4}>30</option>
							<option value={EnRuta.incremento5}>35</option>
							<option value={EnRuta.incremento6}>40</option>
							<option value={EnRuta.incremento7}>45</option>
							<option value={EnRuta.incremento8}>50</option>
						</select>
					</div>

					<div className="total-box">
						{props.enRuta === 0
							? new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(0)
									.replace(/\.00$/, '')
							: new Intl.NumberFormat('es-MX', {
									style: 'currency',
									currency: 'MXN',
							  })
									.format(props.enRuta)
									.replace(/\.00$/, '')}
					</div>
				</div>
			</div>
		</>
	);
};
