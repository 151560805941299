import React from 'react';

import '../styles/moduleT.css';

interface FaelProps {
  fael: number;
  handleFael: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FaelT = (props: FaelProps) => {
  return (
    <>
      <div className="component">
        <div className="grid-group" title="Servicio en suscripción con pagos mensuales - Solo aplica si se cuenta con el módulo de Punto de Venta">
          <div style={{ display: 'flex', alignItems: 'center' }} className="module-box" title="Servicio en suscripción con pagos mensuales - Solo aplica si se cuenta con el módulo de Punto de Venta">Facturador en Línea
            <span className="info-icon" style={{
              display: 'inline-block',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: '#007bff',
              color: 'white',
              textAlign: 'center',
              lineHeight: '16px',
              fontSize: '12px',
              marginLeft: '5px',
              cursor: 'help'
            }}>
              i
            </span>
          </div>

          <div className="radio-box"></div>
          <div className="radio-box"></div>
          <div className="radio-box"></div>
          <div className="radio-box"></div>
          <div className="radio-box"></div>
          <div className="radio-box"></div>

          <div className="select-box">
            {/*  input text number*/}

            <input
              className="fael-input"
              name="fael"
              placeholder="Por RFC"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                backgroundColor: '#c8c9ca',
                fontSize: '0.7rem',
                padding: '0.5rem',
              }}
              onChange={props.handleFael}
              type="number"
              max="1"
              min="0"
            />
          </div>

          <div className="total-box">
            {props.fael === 0
              ? new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
              })
                .format(0)
                .replace(/\.00$/, '')
              : new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
              })
                .format(props.fael)
                .replace(/\.00$/, '')}
          </div>
        </div>
      </div>
    </>
  );
};
