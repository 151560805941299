import styles from './TotalTrad.module.css';

type TotalTradicionalProps = {
	getTotal: () => number;
	getDiscount: () => number | string;
};

export const TotalTradicional = (props: TotalTradicionalProps) => {
	let RadiosDiscountChecked = document.querySelectorAll(
		'input[id="discount"]:checked'
	) as NodeListOf<HTMLInputElement>;

	let RadiosNotDiscount = document.querySelectorAll(
		// 'input[id="NOT-discount"]:not(:checked)'
		'input[id="NOT-discount"]:checked'
	) as NodeListOf<HTMLInputElement>;

	return (
		<>
			<div className={styles.box}>
				<div className={styles.totalContainer}>
					<div className={styles.price}>
						<div>Sub Total</div>
						{/* {RadiosDiscountChecked.length + RadiosNotDiscount.length === 0
							? 'MX$0.00'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotal())} */}
						{
							// if props.getTotal() is 0 or less than 0, show empty string
							props.getTotal() <= 0
								? 'MX$0.00'
								: new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'MXN',
								  }).format(props.getTotal())
						}
						{/* {new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'MXN',
						}).format(props.getTotal())} */}
						{/* use local string to format number*/}
					</div>
					<div className={styles.price}>
						<div>Descuento Por Volumen</div>
						{/* {RadiosDiscountChecked.length + RadiosNotDiscount.length === 0 && Number(props.getDiscount()) > 0
							? 'MX$0.00'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscount()))} */}
						{props.getDiscount() <= 0
							? 'MX$0.00'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(Number(props.getDiscount()))}
						{/* {new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'MXN',
						}).format(Number(props.getDiscount()))} */}
					</div>
					<div className={styles.price}>
						<div>Total</div>
						{props.getTotal() - Number(props.getDiscount()) <= 0
							? 'MX$0.00'
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'MXN',
							  }).format(props.getTotal() - Number(props.getDiscount()))}
						{/* {new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'MXN',
						}).format(props.getTotal() - Number(props.getDiscount()))} */}
					</div>
					<div className={styles.advice}>
						Nota: Esta es sólo una estimación. No incluye IVA, instalación, ni PDA.
					</div>
				</div>
			</div>
		</>
	);
};
