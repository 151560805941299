import styles from './HeaderMO.module.css';

interface HeaderMOProps {
  textTitle: string;
  moduleTitle?: string;
}

export const HeaderModulesOne = (props: HeaderMOProps) => {

  return (
    <>
      <div className={styles.gridTitle}>
        {/*apply style only if prop exists*/}
        <div className={
          props.moduleTitle ? styles.modulosTitle : ''
        }>
          {props.moduleTitle}
        </div>
        <div className={styles.itemTitle}>{
          props.textTitle
        }</div>
      </div>
    </>
  )
}